import React from "react";
import AccordionItem from "../accordion";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { addTimeout } from "../../utils/timeManager";

class OtherIncome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            taxFreeLumpSumPercentage: props.taxFreeLumpSumPercentage || 25,
            definedBenefitIncome: props.definedBenefitIncome || 0.0,
            definedBenefitIncomeFreq: props.definedBenefitIncomeFreq || 'yearly',
            definedBenefitIncomeAge: props.definedBenefitIncomeAge || 0,
            otherIncome: props.otherIncome || 0.0,
            otherIncomeFreq: props.otherIncomeFreq || 'yearly',
            otherIncomeAge: props.otherIncomeAge || 0,
            spa: props.spa
        }
    }

    onTaxFreeLumpSumChange = (event) => {
        let value = event;
        if (typeof event === 'object') {
            value = event.target.value;
        }
        this.setState({taxFreeLumpSumPercentage: value});
        this.onDataChange();
    }

    onDefinedBenefitIncomeChange = (event) => {
        this.setState({
            definedBenefitIncome: event.target.value
        })
        this.onDataChange();
    }

    onDefinedBenefitIncomeFreqChange = (event) => {
        this.setState({
            definedBenefitIncomeFreq: event.target.value
        })
        this.onDataChange();
    }

    onDefinedBenefitIncomeAgeChange = (event) => {
        this.setState({
            definedBenefitIncomeAge: event.target.value
        })
        this.onDataChange();
    }

    onOtherIncomeChange = (event) => {
        this.setState({
            otherIncome: event.target.value
        })
        this.onDataChange();
    }

    onOtherIncomeFreqChange = (event) => {
        this.setState({
            otherIncomeFreq: event.target.value
        })
        this.onDataChange();
    }

    onOtherIncomeAgeChange = (event) => {
        this.setState({
            otherIncomeAge: event.target.value
        })
        this.onDataChange();
    }

    onDataChange = () => {
        addTimeout(() => {
            this.props.onDataChange(this.state);
        });
    }

    render() {
        return <div className={'other-income'}>
            <h2>Tell us how much you want to take as a tax-free lump sum</h2>
            <p>You are entitled to take up to 25% tax free. Adjust the amount you could take to see the change in your retirement income.</p>
            <div className={'input-block flex reverse items-center'}>
                <Slider min={0} max={25} value={this.state.taxFreeLumpSumPercentage} onChange={this.onTaxFreeLumpSumChange} />
                <div className={'prefix-label-input ml-6 mb-2 lg:mb-0'}>
                    <span>%</span><input type="number" min={0} max={25}
                                              value={this.state.taxFreeLumpSumPercentage}
                                              onChange={this.onTaxFreeLumpSumChange}/>
                </div>
            </div>
            <p> Taking <strong>{this.state.taxFreeLumpSumPercentage}%</strong> of your estimated total pension pot of <strong>£0</strong> provides a cash payment of <strong>£0</strong>.</p>
            <h2>Tell us about any defined benefit pension income (salary related)</h2>
            <p>If you don’t have a defined benefit pension, leave this section blank.</p>
            <div className={'input-block'}>
                <div className={'prefix-label-input'}>
                    <span>&#163;</span><input value={this.state.definedBenefitIncome}
                                         onChange={this.onDefinedBenefitIncomeChange} type="number"/>
                </div>
                <select onChange={this.onDefinedBenefitIncomeFreqChange}>
                    <option selected="selected" value="yearly">/ year</option>
                    <option value="monthly">/ month</option>
                    <option value="weekly">/ week</option>
                </select>
                <div>
                    Age  <input value={this.state.definedBenefitIncomeAge}
                                onChange={this.onDefinedBenefitIncomeAgeChange} type="number"/>  Years
                </div>
            </div>
            <AccordionItem title={'What is a defined benefit pension?'}
                           text={`You get a defined benefit pension from a workplace scheme that promises you a certain amount of pension at retirement. Usually the pension is a percentage of the pay you were getting while working. Examples are final salary schemes and career average salary schemes.`}/>
            <h2>Tell us about any other income</h2>
            <p>Enter any other income you're expecting to get in retirement (for example, from savings, property or a pension from a deceased partner. If you're not expecting any, leave this section blank)</p>
            <div className={'input-block'}>
                <div className={'prefix-label-input'}>
                    <span>&#163;</span><input value={this.state.otherIncome} onChange={this.onOtherIncomeChange} type="number"/>
                </div>
                <select onChange={this.onOtherIncomeFreqChange}>
                    <option selected="selected" value="yearly">/ year</option>
                    <option value="monthly">/ month</option>
                    <option value="weekly">/ week</option>
                </select>
                <div>
                    Age  <input value={this.state.otherIncomeAge}
                                onChange={this.onOtherIncomeAgeChange} type="number"/>  Years
                </div>
            </div>
            <AccordionItem title={'What other income might I get?'}
                           text={`For example, this might be income from non-pension savings you have built up or from properties you rent out.`}/>
            <h2>State Pension income</h2>
            <p>Your State Pension age is:</p>
            <p><strong>{this.state.spa.years} years {this.state.spa.months > 0 && <span>{this.state.spa.months} months</span>}</strong></p>
            <p>You'll start getting your new State Pension from:</p>
            <p><strong>{this.state.spa.pensionDate.format('D MMMM YYYY')}</strong></p>
            <p>For the purpose of the calculator, we’ve assumed you will receive the full new State Pension which is:</p>
            <p><strong>£179.60</strong> per week. <strong>How much you actually get depends on your National Insurance Contribution record.</strong></p>
            <AccordionItem title={'How is the State Pension calculated?'}
                           text={`
                                <p>If you are a man born on or after 6 April 1951 or a woman born on or after 6 April 1953 then we assume you’ll get the full amount of the new State Pension in force from 6 April 2016 <b>- but the amount you get depends on your National Insurance Contribution record. You may get more or less than this.</b></p>
                                <p>If you were born before these dates we assume you are receiving the full basic State Pension that applied up to 5 April 2016.</p>
                                <p>You can find out more about the <a href="https://www.yourpension.gov.uk/" target="_blank">new State Pension and how to get a State Pension statement here</a>.</p>
                           `}/>
        </div>
    }
}

export default OtherIncome;
