import React from "react";
import '../../css/components/_pension-calculator.scss';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import About from "./about";
import Income from "./income";
import Contributions from "./contributions";
import OtherIncome from "./other-income";
import Results from "./results";
import moment from "moment";
import {uniqBy as _uniqBy} from 'lodash';
import { addTimeout } from "../../utils/timeManager";

class PensionCalculator extends React.Component {
    calculationAssumptions = {
        salaryIncrease: 2.5/100,
        pensionCharges: 0.75/100,
        investmentGrowth: 5/100,
        inflation: 2.5/100,
        statePensionIncrease: 2.5/100,
        retirementExpenses: 4/100,
        statePensionAmount: 9339,
        retirementAnnuity: 18.85
    }

    daysMapping = {
        yearly: 365.25,
        monthly: 30.4375,
        weekly: 7.0
    }

    tabsUIElement = null;

    constructor(props) {
        super(props);
        this.tabRefs = [
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef()
        ]
        this.tabRef = (node) => {
            if(!node || this.tabsUIElement) return;
            this.tabsUIElement = node.firstElementChild;
        };
        this.state = {
            // we can skip initialization here, but it will give idea what fields are there
            showCalculator: false,
            about: {
                dob: null,
                gender: null,
                retirementAge: null,
                spa: null
            },
            income: {
                salary: null,
                salaryFreq: 'yearly',
                oneDaySalary: 0,
                targetIncome: null,
                targetFreq: 'yearly',
                oneDayTargetIncome: 0
            },
            contributions: {
                pots: [{
                    value: 0
                }],
                grossContribution: {
                    employee: {
                        value: 20.0,
                        type: "percentage",
                        frequency: "monthly"
                    },
                    employer: {
                        value: 20.0,
                        type: "percentage",
                        frequency: "monthly"
                    }
                }
            },
            otherIncome: {
                taxFreeLumpSumPercentage: 25,
                definedBenefitIncome: 0.0,
                definedBenefitIncomeFreq: 'yearly',
                definedBenefitIncomeAge: 0,
                otherIncome: 0.0,
                otherIncomeFreq: 'yearly',
                otherIncomeAge: 0
            },
            result: {
                tableData: [],
                employerContribution: 0,
                employerContributionPercentage: 0,
                employeeContribution: 0,
                employeeContributionPercentage: 0
            },
            selectedIndex: 0
        }
        //this.state = {"about":{"dob":"","gender":"male","retirementAge":"65","income":{"salary":"65000","salaryFreq":"yearly","oneDaySalary":177.9603011635866,"targetIncome":"52000.00","targetFreq":"yearly","oneDayTargetIncome":142.3682409308693},"contributions":{"pots":[{"value":1000}],"grossContribution":{"employee":{"value":20,"type":"percentage","frequency":"monthly"},"employer":{"value":20,"type":"percentage","frequency":"monthly"}}},"otherIncome":{"taxFreeLumpSumPercentage":25,"definedBenefitIncome":0,"definedBenefitIncomeFreq":"yearly","definedBenefitIncomeAge":null,"otherIncome":0,"otherIncomeFreq":"yearly","otherIncomeAge":null},"selectedIndex":0}
    }

    onDataChange = (key, data) => {

        if (key === 'results') {
            const stateData = Object.assign({}, this.state);
            stateData.contributions.grossContribution.employee.value = data.employeeContributionPercentage;
            stateData.contributions.grossContribution.employee.type = 'percentage';
            stateData.contributions.grossContribution.employee.frequency = 'monthly';
            stateData.contributions.grossContribution.employer.value = data.employerContributionPercentage;
            stateData.contributions.grossContribution.employee.type = 'percentage';
            stateData.contributions.grossContribution.employer.frequency = 'monthly';
            stateData.about.retirementAge = data.retirementAge;
            stateData.income.oneDaySalary = data.oneDaySalary;
            stateData.income.yearlySalary = data.yearlySalary;
            stateData.income.oneDayTargetIncome = data.yearlyTargetIncome / 365.25;
            this.setState(stateData);
        } else {
            const newState = Object.assign(this.state[key], data);
            this.setState({[key]: newState});
        }

        addTimeout(() => {
            switch (key) {
                case 'otherIncome':
                case 'results':
                    this.calculatePensionPots();
            }
        });
    }

    calculatePensionPots = () => {
        const pots = +this.state.contributions.pots.reduce((accumulator, pot) => {
            return accumulator + pot.value
        }, 0);
        const pensionPotNetRealIncrease = (1+this.calculationAssumptions.investmentGrowth)/(1+this.calculationAssumptions.pensionCharges)/(1+this.calculationAssumptions.inflation)-1;
        let retirementDate;
        try {
            retirementDate = this.state.about.dob.add(this.state.about.retirementAge, 'years');
        } catch(e) {
            retirementDate = moment(this.state.about.dob).add(this.state.about.retirementAge, 'years');
        }
        const yearsToRetirement = Math.abs(moment().diff(retirementDate, 'y', true));
        const currentPotAtRetirement = pots*Math.pow(1+pensionPotNetRealIncrease, yearsToRetirement);
        const totalContributions = this.calculateGrossContribution(); //Employee gross contribution + Employer contribution
        const contributionAccumulationFactor = (1-Math.pow(1+pensionPotNetRealIncrease, -yearsToRetirement))/pensionPotNetRealIncrease*Math.pow(1+pensionPotNetRealIncrease, yearsToRetirement);
        const accumulatedContributionAtRetirement = totalContributions * contributionAccumulationFactor;
        const totalPensionPotBeforeExpensesAndTFCLS = accumulatedContributionAtRetirement + currentPotAtRetirement;
        const totalPensionPotAfterTFCLS = totalPensionPotBeforeExpensesAndTFCLS * (1-(this.state.otherIncome.taxFreeLumpSumPercentage/100));
        /*const totalRetirementPotLessExpenses = totalPensionPotAfterTFCLS * (1-(this.calculationAssumptions.retirementExpenses/100));

        const potIncomeAtRetirementAge = totalRetirementPotLessExpenses/this.calculationAssumptions.retirementAnnuity;

        const statePensionAge = this.state.about.spa.years + (this.state.about.spa.months/12);

        const retirementDefinedBenefitPensionYears = this.state.otherIncome.definedBenefitIncomeAge - this.state.about.retirementAge;
        const potIncomeAtDefinedBenefitPensionAge = retirementDefinedBenefitPensionYears < 0 ? 0 : potIncomeAtRetirementAge * Math.pow(1 - (this.calculationAssumptions.inflation / 100), retirementDefinedBenefitPensionYears);

        const retirementOtherIncomeAge = this.state.otherIncome.otherIncomeAge - this.state.about.retirementAge;
        const potIncomeAtOtherIncomeAge = retirementOtherIncomeAge < 0 ? 0 : potIncomeAtRetirementAge * Math.pow(1 - (this.calculationAssumptions.inflation / 100), retirementOtherIncomeAge);

        const retirementStatePensionAge = statePensionAge - this.state.about.retirementAge;
        const potIncomeAtStatePensionAge = retirementStatePensionAge < 0 ? 0 : potIncomeAtRetirementAge * Math.pow(1 - (this.calculationAssumptions.inflation / 100), retirementStatePensionAge);*/

        const potIncomeAtRetirementAge = totalPensionPotAfterTFCLS;
        const statePensionAge = this.state.about.spa.years + (this.state.about.spa.months/12);

        const retirementDefinedBenefitPensionYears = this.state.otherIncome.definedBenefitIncomeAge - this.state.about.retirementAge;
        const potIncomeAtDefinedBenefitPensionAge = retirementDefinedBenefitPensionYears === 0 ? potIncomeAtRetirementAge : 0;

        const retirementOtherIncomeAge = this.state.otherIncome.otherIncomeAge - this.state.about.retirementAge;
        const potIncomeAtOtherIncomeAge = retirementOtherIncomeAge === 0 ? potIncomeAtRetirementAge : 0;

        const retirementStatePensionAge = statePensionAge - this.state.about.retirementAge;
        const potIncomeAtStatePensionAge = retirementStatePensionAge === 0 ? potIncomeAtRetirementAge : 0;

        const result = [
            {
                label: "Retirement Age",
                age: +this.state.about.retirementAge,
                potIncome: potIncomeAtRetirementAge
            }
        ];
        this.state.otherIncome.definedBenefitIncomeAge && result.push({
            label: "Defined Benefit Pension Income Age",
            age: +this.state.otherIncome.definedBenefitIncomeAge,
            potIncome: potIncomeAtDefinedBenefitPensionAge
        });
        this.state.otherIncome.otherIncomeAge && result.push({
            label: "Other Income Age",
            age: +this.state.otherIncome.otherIncomeAge,
            potIncome: potIncomeAtOtherIncomeAge
        });
        result.push({
            label: "State Pension Age",
            age: +statePensionAge,
            potIncome: potIncomeAtStatePensionAge
        });
        const yearlyTargetIncome = this.state.income.oneDayTargetIncome * 365.25;
        result.forEach(data => {
            data['statePension'] = data.age >= statePensionAge ? this.calculationAssumptions.statePensionAmount : 0;
            data['definedBenefit'] = data.age >= this.state.otherIncome.definedBenefitIncomeAge ? this.state.otherIncome.definedBenefitIncome : 0;
            data['otherIncome'] = data.age >= this.state.otherIncome.otherIncomeAge ? this.state.otherIncome.otherIncome : 0;
            data['estimatedIncome'] = (+data['statePension']) + (+data['definedBenefit']) + (+data['otherIncome']) + (+data.potIncome);
            data['shortFallSurPlus'] = data['estimatedIncome'] - yearlyTargetIncome;
        });
        result.sort((a,b) => (a.age > b.age) ? 1 : ((b.age > a.age) ? -1 : 0));
        const updatedData = this.state.result;
        updatedData.tableData = _uniqBy(result, 'age');
        this.setState({result: updatedData});
    }

    calculateGrossContribution = () => {
        let employeeContribution = 0;
        let employerContribution = 0;

        if(this.state.contributions.grossContribution.employee.type === 'percentage') {
            const employeeOneDayContribution = this.state.income.oneDaySalary * (this.state.contributions.grossContribution.employee.value / 100);
            employeeContribution = employeeOneDayContribution * this.daysMapping.yearly;
        } else {
            employeeContribution = (this.state.contributions.grossContribution.employee.value / this.daysMapping[this.state.contributions.grossContribution.employee.frequency]) * this.daysMapping.yearly;
        }

        if(this.state.contributions.grossContribution.employer.type === 'percentage') {
            const employerOneDayContribution = this.state.income.oneDaySalary * (this.state.contributions.grossContribution.employer.value / 100);
            employerContribution = employerOneDayContribution * this.daysMapping.yearly;
        } else {
            employerContribution = (this.state.contributions.grossContribution.employer.value / this.daysMapping[this.state.contributions.grossContribution.employer.frequency]) * this.daysMapping.yearly;
        }

        const updatedData = this.state.result;
        updatedData.employeeContribution = employeeContribution;
        updatedData.employeeContributionPercentage = +((employeeContribution / (this.state.income.oneDaySalary * 365.25)) * 100).toFixed(2);
        updatedData.employerContribution = employerContribution;
        updatedData.employerContributionPercentage = +((employerContribution / (this.state.income.oneDaySalary * 365.25)) * 100).toFixed(2);

        this.setState({result: updatedData})

        return employeeContribution + employerContribution;
    }

    onTabChange = (index) => {
        this.setState({selectedIndex: index})
        this.updateUi();
    }

    previous = () => {
        const index = this.state.selectedIndex;
        if(index === 0) {
            return;
        }
        this.setState({selectedIndex: index - 1});
        this.updateUi();
    }

    next = () => {
        const index = this.state.selectedIndex;
        if(index === 4) {
            return;
        }
        this.setState({selectedIndex: index + 1});
        this.updateUi();
        addTimeout(() => {
            switch (index) {
                case 3:
                    this.calculatePensionPots();
            }
        });
    }

    isTabDisabled = (index) => {
        return index > this.state.selectedIndex;
    }

    updateUi = () => {
        addTimeout(() => {
            this.tabsUIElement.scrollLeft = this.state.selectedIndex * 225;
        });
    }

    isAboutValid = () => {
        if(!this.state.about.dob) {
            return false;
        }
        if(!this.state.about.gender) {
            return false;
        }
        if(!this.state.about.retirementAge) {
            return false;
        }
        if (+this.state.about.retirementAge < 55 || +this.state.about.retirementAge > 80) {
            return false;
        }
        const age = moment().diff(moment(this.state.about.dob), 'y');
        return age <= +this.state.about.retirementAge
    }

    isIncomeValid = () => {
        return !!this.state.income.salary;
    }

    isContributionsValid = () => {
        return true;
    }

    isOtherIncomeValid = () => {
        let isValid = true;
        const age = moment().diff(moment(this.state.about.dob), 'y');
        const definedBenefitIncomeAge = +this.state.otherIncome.definedBenefitIncomeAge;
        const otherIncomeAge = +this.state.otherIncome.otherIncomeAge;
        if (isValid && definedBenefitIncomeAge) {
            isValid = definedBenefitIncomeAge >= age && definedBenefitIncomeAge >= 55 && definedBenefitIncomeAge <= 99;
        }

        if (isValid && otherIncomeAge) {
            isValid = otherIncomeAge >= age && otherIncomeAge >= 55 && otherIncomeAge <= 99;
        }

        return isValid;
    }

    shouldNextDisable = () => {
        switch (this.state.selectedIndex) {
            case 0:
                return !this.isAboutValid();
            case 1:
                return !this.isIncomeValid();
            case 2:
                return !this.isContributionsValid();
            case 3:
                return !this.isOtherIncomeValid();
        }
    }

    render() {
        return <section className={"font-arial"}>
            <div className={"lg:p-4 mb-4 lg:max-w-[580px] mx-auto show-hide-bar-pension-calculator"}>
                <div className={`flex w-full justify-between items-center cursor-pointer pension-calculator${this.state.showCalculator ? '-close' : '-open'}`} onClick={() => {this.setState({showCalculator: !this.state.showCalculator})}}>
                    <h4 className={"text-lg lg:text-2xl text-white lg:uppercase font-semibold mb-0 w-full bg-denim text-center py-2"}>Show Pension Calculator</h4>
                    <div className={"rounded-full bg-denim flex justify-center items-center"} style={{width: '120px', height: '120px', minWidth: '120px'}}>
                        <img src={"/images/icons/calculator.svg"} alt={"calculator"} width={50} height={50}/>
                    </div>
                </div>
            </div>
            {this.state.showCalculator && <div className={'pension-calculator'} data-datocms-noindex>
                <Tabs domRef={this.tabRef.bind(this)} selectedIndex={this.state.selectedIndex} onSelect={this.onTabChange}>
                    <TabList>
                        <Tab ref={this.tabRefs[0]}>About you</Tab>
                        <Tab disabled={this.isTabDisabled(1)} ref={this.tabRefs[1]}>Your income</Tab>
                        <Tab disabled={this.isTabDisabled(2)} ref={this.tabRefs[2]}>Pension contributions</Tab>
                        <Tab disabled={this.isTabDisabled(3)} ref={this.tabRefs[3]}>Lump sum & other income</Tab>
                        <Tab disabled={this.isTabDisabled(4)} ref={this.tabRefs[4]}>Your results</Tab>
                    </TabList>
                    <TabPanel><About {...this.state.about} onDataChange={(data) => {this.onDataChange('about', data)}}/></TabPanel>
                    <TabPanel><Income {...this.state.income} onDataChange={(data) => {this.onDataChange('income', data)}}/></TabPanel>
                    <TabPanel><Contributions {...this.state.contributions} onDataChange={(data) => {this.onDataChange('contributions', data)}}/></TabPanel>
                    <TabPanel><OtherIncome {...this.state.otherIncome} spa={this.state.about.spa} onDataChange={(data) => {this.onDataChange('otherIncome', data)}}/></TabPanel>
                    <TabPanel><Results {...this.state} onDataChange={(data) => {this.onDataChange('results', data)}}/></TabPanel>
                </Tabs>
                <div className={"action-container"}>
                    {this.state.selectedIndex > 0 && <button className={'pt-button secondary lg:mr-4'} onClick={this.previous}>Previous</button>}
                    {this.state.selectedIndex < 4 && <button className={`pt-button step-${this.state.selectedIndex + 2}`} onClick={this.next} disabled={this.shouldNextDisable()}>Next</button>}
                </div>

                {this.state.selectedIndex === 4 && <div>
                    <div className={"flex items-center"}>
                        <img src={"/images/icons/info.svg"} width={46} height={46} alt={'disclaimer'}/>
                        <div className={"ml-4 text-denim text-lg font-semibold"}>Pension Calculator Disclaimer</div>
                    </div>
                    <div className={"text-text-300 my-4"}>
                        This pension calculator is provided by Pension Times Ltd for illustrative purposes only and should not be relied upon as an accurate indication of your financial position. As each individual’s circumstances are different and change over time, the results are limited by the accuracy of the assumptions you make in providing the information used in the calculator.
                        You should also beware that none of these figures are guaranteed, they are all estimates based on assumptions that may not be borne out in reality. The actual size of your pension fund will depend on a variety of factors, leaving you with a financial sum that differs, perhaps significantly, from the estimates provided by this calculator. <br/>
                        You should note the figures provided by the calculator are based on a total fund amount that you will use throughout your retirement.<br/>
                        Pension Times Ltd does not offer this calculator in conjunction with any financial advisor or pension provider, and takes no responsibility for any decisions you make regarding your pension(s) following the use of this tool.
                    </div>
                </div>}

                <div className={"w-full bg-denim-200 border-b-4 border-denim mb-6"} style={{height: '20px'}}></div>
            </div>}
        </section>
    }
}

export default PensionCalculator;
