import React from "react";
import AccordionItem from "../accordion";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import moment from "moment";
import {isBrowser} from "../../utils/articleUtil";
import { addTimeout } from "../../utils/timeManager";

class About extends React.Component {

    getSpaData = () => {
        isBrowser() && fetch('/state-pension-age.json'
            ,{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(res => {
            return res.json();
        }).then(res => {
            this.spaData = {male: [], female: []};
            res.forEach(row => {
                this.spaData.male.push({
                    dob: moment(row.men_dob, "DD/MM/YYYY"),
                    years: row.men_years,
                    months: row.men_months,
                    pensionDate: row.men_pension_date
                });
                this.spaData.female.push({
                    dob: moment(row.women_dob, "DD/MM/YYYY"),
                    years: row.women_years,
                    months: row.women_months,
                    pensionDate: row.women_pension_date
                });
            });
        });
    }

    constructor(props) {
        super(props);
        this.maxDob = moment().subtract(20, 'years').toDate();
        this.minDob = moment().subtract(75, 'years').toDate();
        this.state = {
            dob: props.dob,
            gender: props.gender,
            retirementAge: props.retirementAge
        }

        this.getSpaData();
    }

    calculateStatePensionAge = (dob, gender) => {
        if (!dob || !gender) {
            return;
        }
        let momentDob = moment(dob);
        let nearest;
        this.spaData[gender].forEach((data) => {
            const diff = momentDob.diff(data.dob);
            if (diff >= 0) nearest = data;
        });

        if(!nearest.pensionDate || nearest.pensionDate === '') {
            nearest.pensionDate = momentDob.add(nearest.years, 'years').add(nearest.months, 'months')
        } else {
            nearest.pensionDate = moment(nearest.pensionDate, "DD/MM/YYYY");
        }
        return {
            years: nearest.years,
            months: nearest.months,
            pensionDate: nearest.pensionDate
        };
    }

    onDobChange = (date) => {
        this.setState({
            dob: date
        });
        this.setState({spa: this.calculateStatePensionAge(date, this.state.gender)});
        this.onDataChange();
    }

    onGenderSelection = (event) => {
        this.setState({
            gender: event.target.value
        });
        this.setState({spa: this.calculateStatePensionAge(this.state.dob, event.target.value)});
        this.onDataChange();
    }

    onRetirementAgeChange = (event) => {
        this.setState({
            retirementAge: +event.target.value
        });
        this.onDataChange();
    }

    onDataChange = () => {
        addTimeout(() => {
            this.props.onDataChange(this.state);
        })
    }

    render() {
        return <div className={'about'}>
            <p className={"required-message"}>* All information is required</p>
            <div className={"lg:flex lg:max-w-1/2 lg:justify-between"}>
                <div className={'input-block'}>
                    <div className={'section-heading'}>
                        Your date of birth *
                    </div>
                    <DatePicker
                        onChange={this.onDobChange}
                        format={"dd/MM/y"}
                        minDate={this.minDob}
                        defaultActiveStartDate={this.maxDob}
                        maxDate={this.maxDob}
                        value={this.state.dob}
                    />
                </div>
                <div className={'input-block'}>
                    <div className={'section-heading'}>
                        Gender *
                    </div>
                    <div onChange={this.onGenderSelection}>
                        <input type="radio" name="gender" checked={this.state.gender === 'male'} id="gender-male" value="male" required="required"/>
                        <label htmlFor="gender-male" className={"ml-2 mr-4"}>Male</label>

                        <input type="radio" name="gender" checked={this.state.gender === 'female'} id="gender-female" value="female" required="required"/>
                        <label htmlFor="gender-female" className={"ml-2 mr-4"}>Female</label>
                    </div>
                </div>
            </div>

            <div className={'input-block'}>
                <p>I would like to retire at the age of * (must be between 55 and 80)</p>
                <input min={55} max={80} type="number" value={this.state.retirementAge} onChange={this.onRetirementAgeChange}/> years
            </div>

            <AccordionItem
                text={'To calculate your State Pension age.'}
                title={'Why do we need your date of birth and gender?'}/>

        </div>
    }
}

export default About;
