import React from "react";
import AccordionItem from "../accordion";
import { addTimeout } from "../../utils/timeManager";

class Contributions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pots: props.pots || [{
                value: 0
            }],
            grossContribution: props.grossContribution || {
                employee: {
                    value: 20.0,
                    type: "percentage",
                    frequency: "monthly"
                },
                employer: {
                    value: 20.0,
                    type: "percentage",
                    frequency: "monthly"
                }
            }
        }
    }

    callOnDataChange () {
        addTimeout(() => {
            this.props.onDataChange(this.state);
        })
    }

    addPot = () => {
        const pots = this.state.pots;
        pots.push({value: 0});
        this.setState({pots});
        this.callOnDataChange();
    }

    removePot = (index) => {
        const pots = this.state.pots;
        pots.splice(index, 1);
        this.setState({pots});
        this.callOnDataChange();
    }

    onPotValueChange = (event, i) => {
        const pots = this.state.pots;
        pots[i].value = +event.target.value;
        this.setState({pots});
        this.callOnDataChange();
    }

    onEmployeeTypeChange = (event) => {
        const grossContribution = this.state.grossContribution;
        grossContribution.employee.type = event.target.value
        this.setState({grossContribution});
        this.callOnDataChange();
    }

    onEmployeeFrequencyChange = (event) => {
        const grossContribution = this.state.grossContribution;
        grossContribution.employee.frequency = event.target.value
        this.setState({grossContribution});
        this.callOnDataChange();
    }

    onEmployerTypeChange = (event) => {
        const grossContribution = this.state.grossContribution;
        grossContribution.employer.type = event.target.value
        this.setState({grossContribution});
        this.callOnDataChange();
    }

    onEmployerFrequencyChange = (event) => {
        const grossContribution = this.state.grossContribution;
        grossContribution.employer.frequency = event.target.value
        this.setState({grossContribution});
        this.callOnDataChange();
    }

    onGrossContributionChange = (event, key) => {
        const grossContribution = this.state.grossContribution;
        grossContribution[key].value = +event.target.value;
        this.setState({grossContribution});
        this.callOnDataChange();
    }

    render() {
        return <div className={'contributions'}>
            <p>If you don't have a pension pot and are not making any contributions you can skip this step.</p>
            <h2>
                Pension pots (defined contribution schemes)
            </h2>
            <p>
                Enter the current values of any pension pots you have - money saved through workplace or personal
                pension schemes over the course of your career.
            </p>
            <AccordionItem title={'What is a pension pot?'}
                           text={`A pension pot (sometimes called a defined contribution pension scheme) builds up a sum of money using your contributions, your employer's contributions (if applicable), contributions from the government in the form of tax relief plus investment returns.`}/>
            {this.state.pots.map((pot, i) =>
                <div className={'input-block'} key={i}>
                    <div>Pension pot {i + 1}</div>
                    <div className={"flex"}>
                        <div className={'prefix-label-input'}>
                            <span>&#163;</span><input type="number" value={pot.value}
                                                      className={'pot-value'}
                                                      onChange={(event) => {this.onPotValueChange(event, i)}}/>
                        </div>
                        {i !== 0 && <button style={{paddingTop: '12px', paddingBottom: '12px'}} className={'pt-button subscribe-button-no small lg:ml-2'} onClick={this.removePot}>Remove</button>}
                    </div>
                </div>
            )}
            <button className={'pt-button subscribe-button-no small add-pot'} onClick={this.addPot}>+ Add pension pot</button> {/*TODO disable button if any of pot is 0*/}
            <h2>
                Tell us about your current contributions to pension pots
            </h2>
            <div className={'section-heading'}>Your gross contributions</div>
            <div className={'input-block'}>
                <select onChange={this.onEmployeeTypeChange} defaultValue={'percentage'} value={this.state.grossContribution.employee.type}>
                    <option selected="selected" value="percentage">%</option>
                    <option value="currency">&#163;</option>
                </select>
                <input className={'gross-contribution'}
                       type="number"
                       value={this.state.grossContribution.employee.value}
                       onChange={(event) => {this.onGrossContributionChange(event, 'employee')}}/>
                <select onChange={this.onEmployeeFrequencyChange} defaultValue={'monthly'} value={this.state.grossContribution.employee.frequency}>
                    <option value="yearly">/ year</option>
                    <option value="monthly">/ month</option>
                    <option value="weekly">/ week</option>
                </select>
            </div>
            <div className={'section-heading'}>Employer's contributions</div>
            <div className={'input-block'}>
                <select onChange={this.onEmployerTypeChange} defaultValue={'percentage'} value={this.state.grossContribution.employer.type}>
                    <option value="percentage">%</option>
                    <option value="currency">&#163;</option>
                </select>
                <input className={'gross-contribution'} type="number"
                       value={this.state.grossContribution.employer.value}
                       onChange={(event) => {this.onGrossContributionChange(event, 'employer')}}/>
                <select onChange={this.onEmployerFrequencyChange} defaultValue={'monthly'} value={this.state.grossContribution.employer.frequency}>
                    <option value="yearly">/ year</option>
                    <option value="monthly">/ month</option>
                    <option value="weekly">/ week</option>
                </select>
            </div>
            <AccordionItem title={'What is a gross contribution?'}
                           text={`A gross contribution is the amount you pay plus tax relief from the government. For example, if you pay £80 and the government adds tax relief of £20, your gross contribution is £100.`}/>
            <AccordionItem title={'How do I know if I\'m currently contributing?'}
                           text={`To check how much your contributions are, you can look at a recent payslip or speak to your HR contact. If you are self-employed, check your bank statement or contact the pension provider. If these show your contribution after tax has been taken off, you can work out the tax relief as: what you pay x 25%. For example, if you pay £80, the tax relief is £80 x 25% = £20.`}/>

        </div>
    }
}

export default Contributions;
